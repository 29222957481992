@import "../../../theme/index.module.scss";

.SearchBar {
  margin-right: 1em;
  cursor: pointer;
}

.SearchBarIcon {

  svg {
    width: 16px;
    height: 16px;
  }

  @include bp(XLarge) {
    margin-top: .3em;

    svg {
      width: 13px;
      height: 13px;
    }
  }
}
